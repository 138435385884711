app-informations-legales {

    .legal-notice {
        display: grid;
        grid-template-rows: auto 1fr;
        min-height: 100%;
        padding: 3rem;
        gap: 2rem;
        background-color: $legal-notice--background-color;
        color: $legal-notice--color;

        .legal-notice__title {
            margin: 0;
            font-size: 1.8rem;
            font-weight: 900;
        }

        .legal-notice__spinner-wrapper {
            margin: auto;
        }

        .legal-notice__accordion {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            max-width: 200rem;

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                max-width: 60vw;
                max-height: 60vh;
                margin-top: 5rem;
                margin-inline: auto;
                background-image: image("backgrounds/gavel.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            .legal-notice__panel {
                @include gradient-border(
                    $border-width: 1px,
                    $angle: to bottom,
                    $starting-color: rgba(255, 255, 255, 1),
                    $starting-color-stop: 40%,
                    $ending-color: transparent,
                    $ending-color-stop: 80%,
                );
                background-color: rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(8.5px);
                border-radius: 8px;
                box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.03);
                transition: all 200ms ease-in-out;

                .legal-notice__panel-header {
                    height: unset;
                    padding: 1rem 1.5rem;
                    background-color: unset;

                    &:hover,
                    &:focus {
                        color: unset;
                        background-color: unset;
                    }

                    .mat-content {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        .legal-notice__panel-title {
                            margin: 0;
                            color: $legal-notice__panel-title--color;
                            font-size: 1.6rem;
                            font-weight: 600;
                        }

                        .legal-notice__panel-icon,
                        .legal-notice__arrow-icon {
                            transform: unset;
                        }

                        .legal-notice__arrow-icon {
                            flex: none;
                            width: 2rem;
                            min-width: unset;
                            height: auto;
                            min-height: unset;
                            aspect-ratio: 1;
                            margin-left: auto;
                            color: $legal-notice__panel-title--color;
                            transition: transform 200ms ease-in-out;

                            svg {
                                width: 100%;
                                min-width: unset;
                                height: 100%;
                                min-height: unset;
                            }
                        }
                    }
                }

                .mat-expansion-panel-content {

                    .mat-expansion-panel-body {
                        padding: 0 0.5rem 0.5rem;

                        .legal-notice__panel-content {
                            padding: 1.5rem 2rem;
                            background-color: $legal-notice__panel-content--background-color;
                            border-radius: 6px;

                            *:first-child {
                                margin-top: 0;
                            }

                            *:last-child {
                                margin-bottom: 0;
                            }

                            a {
                                color: $legal-notice__link--color;
                                text-decoration: $legal-notice__link--text-decoration;
                            }
                        }
                    }
                }

                &.mat-expanded {
                    margin: 0;
                    box-shadow: $legal-notice__panel--expanded--box-shadow;

                    .legal-notice__panel-header {

                        .mat-content {

                            .legal-notice__arrow-icon {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
    }
}