// --------------------------------------------------
// LAYOUT
// --------------------------------------------------
.activity-search-engine__dialog {
    width: 100%;
    height: 100%;
    max-height: 80vh;

    app-activity-search-engine-dialog {

        app-activity-search-engine-panel {
            background-color: var(--faux_blanc, #F9FBFF);
            flex: 1;
            display: flex;
            position: relative;

            app-back-panel {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                bottom: 0;
                inset: 0;
                .mat-dialog-title {
                    margin: 0;
                    h2 {
                        margin-right: auto;
                    }

                    button, .app-activity-search-engine-panel__selected-count-label {
                        min-height: 44px !important;
                    }

                    .app-activity-search-engine-panel__selected-count-label {
                        @include button(tertiary, $group-button-position: start);
                        mat-icon.mat-icon svg { color: var(--vert, #01844D) !important; }
                        span {
                            font-weight: normal;
                            font-size: 1.4rem;
                        }
                    }
                    .app-activity-search-engine-panel__clear-selection-button {
                        @include button(tertiary, $group-button-position: middle);
                        span {
                            text-decoration: underline;
                            font-weight: normal;
                            font-size: 1.4rem;
                        }
                    }
                    .app-activity-search-engine-panel__validate-selection-button {
                        @include button(primary, $group-button-position: end, $icon-on-Right: true);
                    }
                    button + button.close {
                        display: none;
                    }
                }
                .mat-dialog-content {
                    display: flex;
                    flex: 1;
                    max-height: 100%;


                    &.filter-is-selected {
                        justify-content: flex-start;
                        flex-direction: column;
                        display: flex;
                        align-items: stretch;
                        background: white;
                        flex-grow: 1;
                        min-height: 0;
                        overflow: hidden;
                        padding: 0  ;
                    }

                    app-filter-bar .mat-chip {
                        @include chips(quaternary);
                    }

                    > .content {
                        flex: 1;
                        display: flex;
                        padding: 0;
                        align-items: center;
                        justify-content: center;
                        max-height: 100%;
                        gap: 16px;

                        &.lessons-search-viewer {
                            align-items: stretch;

                        }

                        h2 {
                            margin: 0;
                            text-align: center;
                            /* H1 Bold 24 */
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }

                        .app-activity-search-engine-panel__icon {
                            @include iconSize(50px);
                        }

                        app-generic-filter-selector .mat-chip {
                            @include chips(primary);
                        }

                        .app-activity-search-engine-panel__button {
                            @include button(secondary, $button-bordered: true);
                        }
                    }



                }
            }
        }
    }
}
// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

app-generic-filter-selector {
    display: flex;
    padding-inline: 20px;
}

app-filter-bar {
    background: var(--noir_5, rgba(0, 0, 0, 0.05));
    padding: 8px;
    display: flex;
    flex: none;
    flex-direction: column;

    .activity-search-engine-panel__button-clear_filters {
        @include button($type: tertiary, $icon-full: false, $text-hide: true );
        margin-left: auto;
    }
    .activity-search-engine-panel__button-resume_filters {
        @include button($type: tertiary );
    }

    > .mat-chip-list {
        background-color: white;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--gris-de-contour, #E0E0E0);
    }

}
app-lessons-search-viewer {
    overflow: auto;
    flex: 0 1 100%;
    scroll-snap-type: y;

    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 20px;

    mat-spinner.mat-progress-spinner {
        position: absolute;
        bottom: -5%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
    }

}
app-lesson-horizontal-viewer {
    @include py-2;
    padding-top: clamp(20px, 2vh + 10.91px, 40px);
    @include pb-0;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 20px;
    width: auto;
    display: flex;
    scroll-snap-align: start;

    border-radius: 5px;
    border: 1px solid var(--gris-de-contour, #CBD1DB);
    background: var(--noir_5, rgba(0, 0, 0, 0.05));
    flex: none;

    .header, .content {
        margin-inline: 50px;
    }


    .header {
        flex: none;
        display: flex;
        @include pb-2;
        justify-content: space-between;

        /* H5 Bold 14 */
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        button {
            color: var(--rouge-tralalere-accessible, #E62716);
            text-align: right;
            /* H6 Bold 12 Souligner */
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline;
            @include button(secondary, $link-style: true);
        }

    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 20px;
        overflow-x: visible;
        overflow-y: hidden;
        scroll-snap-type: x;

        app-pretty-activity-card {
            flex: 1;
            width: auto;
            height: 30vh;
            min-height: 32.5rem;
            max-height: 40rem;
            aspect-ratio: 2/2.75;
            margin-bottom: 17px;
            scroll-snap-align: start;
        }
    }
}
.app-lesson-horizontal-viewer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: auto;

    padding: 15px 16px;

    border-radius: var(--Radius-L, 10px);
    background: var(--noir-5, rgba(0, 0, 0, 0.05));
}