app-keywords {
    display: flex;
    gap: 6px;

    .keywords {
        flex: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 14px;
        // Style
        border-radius: 1em;
        background: lighten(desaturate(adjust-hue(#E8EDF6, 1), 20.67), 1.18);
        // Font
        font-size: 1.2rem;
        font-weight: 700;
        line-height: normal;
        text-wrap: nowrap;
    }
}