@use '../../../../../../node_modules/@angular/material/index' as mat;

// --------------------------------------------------
// H1
// --------------------------------------------------

@mixin h1-font {
    @include mat-typography-level-to-styles($typography, 'headline'); // Emit all CSS styles of the headline typography level
}

@mixin h1-font-family {
    font-family: mat.font-family($typography, 'headline');
}

@mixin h1-font-size {
    font-size: mat.font-size($typography, 'headline');
}

@mixin h1-font-weight {
    font-weight: mat.font-weight($typography, 'headline');
}

@mixin h1-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'headline');
}

@mixin h1-line-height {
    line-height: mat.line-height($typography, 'headline');
}

// --------------------------------------------------
// H2
// --------------------------------------------------

@mixin h2-font {
    @include mat-typography-level-to-styles($typography, 'title'); // Emit all CSS styles of the title typography level
}

@mixin h2-font-family {
    font-family: mat.font-family($typography, 'title');
}

@mixin h2-font-size {
    font-size: mat.font-size($typography, 'title');
}

@mixin h2-font-weight {
    font-weight: mat.font-weight($typography, 'title');
}

@mixin h2-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'title');
}

@mixin h2-line-height {
    line-height: mat.line-height($typography, 'title');
}

// --------------------------------------------------
// H3
// --------------------------------------------------

@mixin h3-font {
    @include mat-typography-level-to-styles($typography, 'subheading-2'); // Emit all CSS styles of the subheading-2 typography level
}

@mixin h3-font-family {
    font-family: mat.font-family($typography, 'subheading-2');
}

@mixin h3-font-size {
    font-size: mat.font-size($typography, 'subheading-2');
}

@mixin h3-font-weight {
    font-weight: mat.font-weight($typography, 'subheading-2');
}

@mixin h3-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'subheading-2');
}

@mixin h3-line-height {
    line-height: mat.line-height($typography, 'subheading-2');
}

// --------------------------------------------------
// H4
// --------------------------------------------------

@mixin h4-font {
    @include mat-typography-level-to-styles($typography, 'subheading-1'); // Emit all CSS styles of the subheading-1 typography level
}

@mixin h4-font-family {
    font-family: mat.font-family($typography, 'subheading-1');
}

@mixin h4-font-size {
    font-size: mat.font-size($typography, 'subheading-1');
}

@mixin h4-font-weight {
    font-weight: mat.font-weight($typography, 'subheading-1');
}

@mixin h4-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'subheading-1');
}

@mixin h4-line-height {
    line-height: mat.line-height($typography, 'subheading-1');
}

// --------------------------------------------------
// BASE BODY TEXT
// --------------------------------------------------

@mixin body-font {
    @include mat-typography-level-to-styles($typography, "body-1"); // Emit all CSS styles of the body-1 typography level
}

@mixin body-font-family {
    font-family: mat.font-family($typography, 'body-1');
}

@mixin body-font-size {
    font-size: mat.font-size($typography, 'body-1');
}

@mixin body-font-weight {
    font-weight: mat.font-weight($typography, 'body-1');
}

@mixin body-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'body-1');
}

@mixin body-line-height {
    line-height: mat.line-height($typography, 'body-1');
}

// --------------------------------------------------
// BOLDER BODY TEXT
// --------------------------------------------------

@mixin bold-font {
    @include mat-typography-level-to-styles($typography, "body-2"); // Emit all CSS styles of the body-2 typography level
}

@mixin bold-font-family {
    font-family: mat.font-family($typography, 'body-2');
}

@mixin bold-font-size {
    font-size: mat.font-size($typography, 'body-2');
}

@mixin bold-font-weight {
    font-weight: mat.font-weight($typography, 'body-2');
}

@mixin bold-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'body-2');
}

@mixin bold-line-height {
    line-height: mat.line-height($typography, 'body-2');
}

// --------------------------------------------------
// SMALLER BODY TEXT
// --------------------------------------------------

@mixin caption-font {
    @include mat-typography-level-to-styles($typography, "caption"); // Emit all CSS styles of the caption typography level
}

@mixin caption-font-family {
    font-family: mat.font-family($typography, 'caption');
}

@mixin caption-font-size {
    font-size: mat.font-size($typography, 'caption');
}

@mixin caption-font-weight {
    font-weight: mat.font-weight($typography, 'caption');
}

@mixin caption-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'caption');
}

@mixin caption-line-height {
    line-height: mat.line-height($typography, 'caption');
}

// --------------------------------------------------
// BUTTONS
// --------------------------------------------------

@mixin button-font {
    @include mat-typography-level-to-styles($typography, "button"); // Emit all CSS styles of the button typography level
}

@mixin button-font-family {
    font-family: mat.font-family($typography, 'button');
}

@mixin button-font-size {
    font-size: mat.font-size($typography, 'button');
}

@mixin button-font-weight {
    font-weight: mat.font-weight($typography, 'button');
}

@mixin button-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'button');
}

@mixin button-line-height {
    line-height: mat.line-height($typography, 'button');
}

// --------------------------------------------------
// FORM INPUT FIELDS
// --------------------------------------------------

@mixin input-font {
    @include mat-typography-level-to-styles($typography, "input"); // Emit all CSS styles of the input typography level
}

@mixin input-font-family {
    font-family: mat.font-family($typography, 'input');
}

@mixin input-font-size {
    font-size: mat.font-size($typography, 'input');
}

@mixin input-font-weight {
    font-weight: mat.font-weight($typography, 'input');
}

@mixin input-letter-spacing {
    letter-spacing: mat.letter-spacing($typography, 'input');
}

@mixin input-line-height {
    line-height: mat.line-height($typography, 'input');
}