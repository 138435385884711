.navbar-fuse-sidebar {
    width: 255px;
    min-width: 255px;
    max-width: 255px;
}

// humanum
navbar {
    &.navbar-background-color {
        background: $navbar-content-background-color;
    }
}

navbar navbar-vertical-style-2 {
    .mat-icon {
        color: $nav-icon-color;
    }

    div.navbar-header {
        gap: $navbar-header--gap;
        height: $navbar-header--height;
        padding: $navbar-header--padding;
        background-color: #{$navbar-header-background-color};
        box-shadow: #{$navbar-header-box-shadow};

        .folded:not(.unfolded) & {
            gap: 0;
            margin-bottom: 65px;
            position: relative;
        }

        .logo {
            height: 100%;

            .folded:not(.unfolded) & {
                position: absolute;
                top: 65px;
                left: 0;
            }

            .link {
                height: 100%;
            }
        }

        .toggle-sidebar-folded,
        .toggle-sidebar-opened {
            width: $navbar-header__menu-button--width;
            height: $navbar-header__menu-button--height;
            transform: $navbar-header__menu-button--transform;

            .mat-button-wrapper {

                .mat-icon {
                    width: $navbar-header__menu-icon--width;
                    height: $navbar-header__menu-icon--height;
                    aspect-ratio: $navbar-header__menu-icon--aspect-ratio;

                    svg {
                        width: auto;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                        aspect-ratio: 1;
                    }
                }
            }
        }
    }

    mat-icon {
        &.logo-icon-svg {
            color: $logo-icon-svg-color;
            width: $logo-icon-svg-width;
            height: $logo-icon-svg-height;

            .folded:not(.unfolded) & {
                // mini sidebar // = .folded [...] mat-icon.logo-icon-svg
                width: 100%;
                min-width: 40px;
                max-width: 152px;
                height: 55px;

                .brand-text {
                    opacity: 0;
                    transition-property: all;
                    transition-duration: 100ms;
                    transition-timing-function: ease;
                }

                .brand-background,
                .brand-text-ia {
                    transform: translate(-327px, -58px) scale(2);
                    transition-property: all;
                    transition-duration: 200ms;
                    transition-timing-function: ease;
                }
            }

            &[svgicon='menu'] {
                width: $navbar-header-icon-dimensions;
                height: $navbar-header-icon-dimensions;
                color: $navbar-header-icon-svg-color;
            }
        }
    }

    .navbar-header .logo img.logo-icon-png {
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
    }

    .nav .nav-item {
        padding-right: $navbar-vertical__item--padding-right;

        + .nav-item {
            margin-top: $navbar-vertical__item--margin-top;
        }

        .nav-link {
            padding: $nav-link--padding;
            border-radius: $nav-link--border-radius;
            font-weight: 700;
            line-height: $nav-link--line-height;

            .nav-link-title {
                color: $nav-link-title-color;
                font-size: $nav-link-title-font-size;
            }


            .nav-link-icon,
            .collapsable-arrow {
                font-size: $nav-link-icon-font-size;
                width: $nav-link-icon-width;
                height: $nav-link-icon-height;
                min-width: $nav-link-icon-width;
                min-height: $nav-link-icon-height;
                line-height: $nav-link-icon-line-height;
                opacity: 1;
            }

            .nav-link-icon {
                margin-right: $nav-link__icon--margin-right;
            }

            &.active {
                background: $nav-link--active--background-color !important;

                .nav-link-title {
                    color: $nav-link-title-color-active;
                }

                .nav-link-icon,
                .collapsable-arrow {
                    color: $nav-link-icon-color-active;
                }
            }

            &:hover {
                background: $nav-link--hover--background-color;

                .nav-link-title {
                    color: $nav-link-icon-color-hover;
                }

                .nav-link-icon,
                .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }
        }



        &.nav-collapsable.open {
            background: #{$nav-collapsable--background};
            border-radius: $nav-collapsable--border-radius;

            &.nav-item > .nav-link {
                background: $nav-link__collapse-open--active--background-color;

                &::before {
                    display: none;
                }
            }

            .children {
                .nav-link {
                    position: relative;
                    padding-left: 90px;
                    background: transparent !important;

                    &.active.mat-accent-bg {
                        &::before {
                            content: '';
                            width: 25px;
                            height: 25px;
                            display: block;
                            color: white;
                            position: absolute;
                            left: 30px;
                            background-color: $nav-link__collapse-open__children__before--active--background-color;
                            -webkit-mask-image: icon(
                                    'sub-menu-nav-link-icon.svg'
                            );
                            mask-image: icon('sub-menu-nav-link-icon.svg');
                        }
                    }

                    &:hover {
                        &::before {
                            content: '';
                            width: 25px;
                            height: 25px;
                            display: block;
                            color: white;
                            position: absolute;
                            left: 30px;
                            background-color: $nav-link__collapse-open__children__before--hover--background-color;
                            -webkit-mask-image: icon(
                                    'sub-menu-nav-link-icon.svg'
                            );
                            mask-image: icon('sub-menu-nav-link-icon.svg');
                        }
                    }
                }

                &:hover {
                    .nav-link:not(:hover) {
                        &::before {
                            display: none !important;
                        }
                    }
                }

                .xnav-link {
                    position: relative;
                    padding-left: 90px;

                    &.active.mat-accent-bg,
                    &:hover {
                        background: transparent !important;

                        &::before {
                            content: '';
                            width: 25px;
                            height: 25px;
                            display: block;
                            color: white;
                            position: absolute;
                            left: 30px;
                            background: icon('sub-menu-nav-link-icon.svg') no-repeat;
                        }
                    }
                }
            }

            .nav-link:first-child {
                .nav-link-icon,
                .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }
        }
    }

    .nav-collapsable {

        &.open {

            .nav-link {

                .collapsable-arrow {
                    transform: $nav-link__collapsable-arrow--transform;
                }
            }
        }
    }

    %secondaryMenuItems {
        .nav-link-icon {
            font-size: 18px;
            width: 18px;
            height: 18px;
            min-width: 18px;
            min-height: 18px;
            line-height: 18px;
        }

        .nav-link-title {
            font-size: 1rem;
        }

        .nav-link {
            height: 24px;
        }
    }

    .nav > {
        .navbar-content {
            display: flex;
            flex-direction: column;
            padding-bottom: $navbar-content--padding-bottom;
            overflow: auto;
        }

        .navbar-content > fuse-nav-vertical-item.nav-item,
        .navbar-content > fuse-nav-vertical-item.menu-link {
            width: 100%;
        }
        // for accessibility permit to see element when tab focus
        .navbar-content > fuse-nav-vertical-item.nav-item a:focus-visible,
        .navbar-content > fuse-nav-vertical-collapsable a:focus-visible {
            width: 96%;
            margin: 5px auto;
        }


        .navbar-content {
            .menu-break {
                margin-top: auto !important;
            }

            .wrapper-menu-item-external-url {
                > a {
                    @extend %button--accent;
                    margin: 20px 40px 20px 20px;
                    min-width: auto;
                    text-align: center;
                    padding-top: 5px;

                    mat-icon {
                        display: none;
                    }
                    span.nav-link-title {
                        color: $button--accent-color;
                    }
                }
            }

            // for humanum add a specific button with img to go to app
            @if $use_button_app_with_img == true {

                .wrapper-menu-item-external-url {
                    position: relative;
                    width: auto !important;
                    max-width: max-content;
                    padding: 110px 20px 0 20px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 100%;
                        height: 100px;
                        background-image: url(/assets/humanum/images/app_redirection.png);
                        background-size: contain;
                        background-position: center bottom;
                        background-repeat: no-repeat;
                        transform: translate(-50%, 10px);
                    }

                    .nav-link {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                        height: auto;
                        padding: 10px;
                        margin: 0;

                        &:hover {
                            background-color: darken(saturate(adjust-hue($accent-c, 3), 1.58), 3.53);

                            span.nav-link-title {
                                color: $button--accent-color;
                            }
                        }

                        &::before { // Gradient border
                            content: '';
                            position: absolute;
                            inset: 0;
                            padding: 1px;
                            border-radius: inherit;
                            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.30) 40%, transparent 80%);
                            mask: linear-gradient(#FFF 0 100%) content-box exclude,
                                  linear-gradient(#FFF 0 100%) border-box add;
                        }

                        &::after {
                            content: '';
                            flex: none;
                            width: 24px;
                            height: 24px;
                            mask-image: icon('add_to_home_screen_outlined_fill.svg');
                            mask-size: contain;
                            mask-position: center bottom;
                            mask-repeat: no-repeat;
                            background-color: white;
                        }

                        .nav-link-title {
                            flex: initial;
                            line-height: 1;
                        }
                    }

                    + .nav-item {
                        margin-top: 10px;
                    }
                }
            }

            .wrapper-menu-item-mentions-legales {
                order: 1;
            }

            .wrapper-menu-item-cgurgpd {
                order: 2;
            }

            .wrapper-menu-item-politique-confidentialite {
                order: 3;
            }

            .wrapper-menu-item-accessibility {
                order: 4;
            }

            .wrapper-menu-item-feedback {
                order: 5;
            }

            .wrapper-menu-item-mentions-legales,
            .wrapper-menu-item-cgurgpd,
            .wrapper-menu-item-politique-confidentialite,
            .wrapper-menu-item-accessibility,
            .menu-link {
                @extend %secondaryMenuItems;
            }
        }
    }

    .menu-link-block {
        position: relative;
    }
}

@if $use_button_app_with_img == true {
    fuse-sidebar.navbar-fuse-sidebar.folded:not(.unfolded) {
        .wrapper-menu-item-external-url {
            a::after, a::before, a span {
                display: none;
            }

            a {
                width: 100%;
                border-radius: 0 !important;
                margin: 0;
                justify-content: center;
            }
        }
    }
}