app-basic-page {
    width: 100%;
    height: 100%;
    color: $default-font-color;

    .page-layout.simple {
        padding-left: 1vh;
        padding-right: 1vh;

        .content {
            width: 100%;
            height: 100%;
        }
    }

    ol {
        counter-reset: count;
    }

    ol li {
        list-style-type: none;
        position: relative;
        padding: 5px;
    }

    ol li:before {
        font-weight: bold;
        counter-increment: count;
        content: counter(count) " ";
        margin-right: 0.5em;
        display: inline-block;
        padding: 0 5px;
        border-radius: 50%;
        color: #fff;
        background: $basic-page__li--background-color;
        height: 20px;
        width: 20px;
        text-align: center;
    }
}