// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/app-action-bar";
@import "components/app-add-lesson-button-morphable"; // emplacement carte néo
@import "components/app-neo"; // intérieur de la carte vers éditeur ( 3 contenus diff. )
@import "components/app-grid";
@import "components/app-pretty-activity-card";
@import "components/app-pretty-card";
@import "components/activity-sample";
@import "components/app-lesson-meta-form";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

/**
 * If you have page-specific styles, it is better to put them in a pages/ folder, in a file named after the page. For instance, it’s not uncommon to have very specific styles for the home page hence the need for a _home.scss file in pages/.
*/

@import "pages/app-user-lessons";
@import "pages/app-editor";
@import "pages/app-editor-panel";
@import "pages/app-lesson-meta-form-as-dialog";
@import "pages/app-activity-search-engine-dialog";