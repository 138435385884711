@function convert-radius($radius, $factor: 0.8) { // figma rem > lms rem
    @return $radius * $factor;
}
// remplace $numbers-??: 0.125rem;
$numbers-unknown: convert-radius(0.125rem);

/* Design Team */
/* color */
// TODO @import "color/variables-primitives-color";

/* number */
$numbers-0: 0rem;
// $numbers-??: 0.125rem;
$numbers-half-1: convert-radius(0.1875rem);
$numbers-1: convert-radius(0.375rem);
$numbers-half-3: convert-radius(0.5625rem);
$numbers-2: convert-radius(0.75rem); // 6
$numbers-half-6: convert-radius(0.9375rem); // 7.5
$numbers-3: convert-radius(1.125rem); // 9
$numbers-4: convert-radius(1.5rem); // 12
$numbers-5: convert-radius(1.875rem); // 15
$numbers-6: convert-radius(2.25rem); // 18
$numbers-7: convert-radius(2.625rem); // 21
$numbers-8: convert-radius(3rem); // 24
$numbers-9: convert-radius(3.375rem); // 27
$numbers-10: convert-radius(3.75rem); // 30
$numbers-11: convert-radius(4.125rem); //
$numbers-20: convert-radius(7.5rem);
$numbers-30: convert-radius(11.25rem);
$numbers-40: convert-radius(15rem);
$numbers-macbook-air-h: convert-radius(47.25rem);
$numbers-rounded: 2rem;
$numbers-macbook-air-w: convert-radius(80rem);