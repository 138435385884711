@use '@angular/material' as mat;
// stepper-wrapper.html charge en contenu lessons.component.html

// Player lesson header
.bem-app-stepper-wrapper__header {
    @include mb-2;
    display: flex;
    padding: 0 var(--padding-lateral-viewport);
    background: var(--color-lessons-header);
    
    button {
        padding: $stepper__button--padding;
    }

    > div { // .header-top (useless container)
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include py-2;
    }

    .fuse-app-lessons__button-help {
        order: 2;
        margin-left: auto;
    }

    .bem-fuse-app-lessons__title {}

    .toolbar {
        flex-direction: row !important;
        flex: 1;

        .fuse-app-lessons__button-tools {
            margin-right: auto;
        }

        .fuse-app-lessons__button-fullscreen {
            margin-left: auto;
        }
    }
}