$fake-card--background-color: null !default;
$fake-card--color: null !default;
$accent-c: null !default;
$default-font-color: null !default;
$card__header--breadcrumb--background-color: null !default;
$card__header--breadcrumb--color: null !default;

app-notepad-list {
    & > div.page-layout.simple {
        flex-basis: inherit; // remove auto
    }

    .notepad-card-list {
        > .content {
            @include mx-2;
            @include my-2;
            @include grid($sm-col: 2, $lg-col: 2, $xl-col: 3, $gap: 22);
        }


        .fake-card {
            @include fake-card;
        }
        .fake-card .mat-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }

    button[mat-fab].mat-fab.mat-fab-bottom-right {
        background-color: $accent-c;

        svg {
            path {
                stroke: $default-font-color;
            }
        }
    }
}

app-notepad-card {

    .mat-card {

        .mat-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}

app-notepad-data-editor-modal {
    span.mat-form-field-label-wrapper label {
        color: $default-font-color !important;
    }
}