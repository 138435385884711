app-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22.5rem, 1fr));
    justify-items: center;
    gap: 1.5rem;
    min-width: min-content;
    padding: 1.5rem;
    background-color: rgba($black-c, 0.05);
    border-radius: 10px;

    > * { // Target all grid items
        width: 100%;
        max-width: 27.5rem;
        aspect-ratio: 2/3;
    }

    .editor-panel__add-activities {
        display: flex;
        flex-direction: column;
        gap: 20px;

        button {
            flex: 1;
            gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            border: 1px solid var(--noir-10, rgba(0, 0, 0, 0.10));
            box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.10),
                        0px 2px 2px 0px rgba(36, 38, 55, 0.05),
                        0px 4px 4px 0px rgba(255, 255, 255, 0.15);

            span {
                @include h4-font;
            }

            mat-icon.mat-icon {
                flex: none;
                width: 30%;
                height: auto;
                aspect-ratio: 1/1;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .editor-panel__add-activities__button-create,
        .editor-panel__add-activities__button-create:active {
            flex: 2;
            color: $white-c;
            background: $accent-c;

            &:hover {
                background: lighten($accent-c, 10%);
            }
        }
        .editor-panel__add-activities__button-import,
        .editor-panel__add-activities__button-import:active {
            flex: 1;
            color: $accent-c;
            background: $white-c;
            margin: 0;

            &:hover {
                background: lighten($accent-c, 10%);
                color: $white-c;
            }
        }

    }
}