/*
*  <mat-form-field.mat-form-field + .mat-form-field-appearance-legacy
*       .mat-form-field-wrapper
*           .mat-form-field-flex
*           |    .mat-form-field-infix
*           |    |
*           |    |    <input>
*           |    |
*           |    |    .mat-form-field-label-wrapper
*           |    |    |- mat-form-field-label            TEXT-DU-LABEL
*           |
*           .mat-form-field-underline
*           |    .mat-form-field-ripple
*           .mat-form-field-subscript-wrapper
*           |    .mat-form-field-hint-wrapper
*           |        .mat-form-field-hint-spacer
*
 */
@use '@angular/material' as mat;

/*
* ************************* STAY LABEL ON TOP ****************************
 */

%mat-form-field-label-on-top {
    .mat-form-field {
        max-width: 100%;
        //padding-top: $form-field-label-height;
        margin-bottom: $form-field-margin-bottom;

        .mat-input-element {
            font: #{$form-field-input-font};
            color: rgba($form-field-input-label-color, 1);
        }

        .mat-select-value {
            color: rgba($form-field-input-label-color, 1);
        }

        // voir theme $input: mat-typography-level
        //&.mat-form-field-can-float
        //&.mat-form-field-should-float
        //&.mat-focused

        &.mat-form-field-should-float,
        &.mat-form-field-can-float,
        &.mat-form-field-appearance-legacy .mat-form-field-wrapper {
            padding-bottom: 0.25em;

            .mat-form-field-flex {
                margin-top: $form-field-label-height;
                border-top: $form-field-flex-border-top;
                border-bottom: $form-field-flex-border-bottom;
                border-left: $form-field-flex-border-left;
                border-right: $form-field-flex-border-right;

                .mat-form-field-infix {
                    padding: #{$form-field-infix-padding};
                    line-height: #{$form-field-infix-line-height};
                    border-top: 0 solid transparent;

                    .mat-input-element {
                        color: $form-field-input-color;

                        &textarea {
                            padding: 18px 12px 10px 10px;

                            + .mat-form-field-label-wrapper {
                            }
                        }
                    }

                    .mat-form-field-label-wrapper {
                        padding-bottom: 0.5em;

                        .mat-form-field-label { /*voir au premiere niveau*/
                            font: #{$form-field-label-font};
                            color: $form-field-input-label-color;
                            text-transform: #{$form-field-label-text-transform};
                            font-weight: 700;
                        }
                    }
                }
            }

            .mat-form-field-ripple, .mat-focused .mat-form-field-ripple {
                background-color: $form-field-ripple-background;
            }

            .mat-form-field-underline {
                background-color: $form-field-underline-background-color;
                bottom: $form-field-underline-bottom;
            }

            .mat-form-field-underline,
            .mat-form-field-ripple,
            .mat-focused .mat-form-field-ripple {
                transform: scaleY(1);
                background-color: $form-field-ripple-background;
            }

            .mat-form-field-subscript-wrapper {
                top: calc(100% - 1em);
                font-weight: 700;
            }
        }

        /*
        * **************** label ****************
         */
        .mat-form-field-label-wrapper {
            top: -#{$form-field-label-height};
            padding-top: $form-field-label-height;
        }

        //.mat-form-field
        &.mat-form-field-can-float,
        &.mat-form-field-should-float,
        &.mat-form-field-can-float.mat-form-field-should-float {
            // avec ou non le style special donc selecteur lambda
            &.mat-form-field-appearance-legacy, .mat-form-field-wrapper {
                .mat-form-field-flex, //selecteur lambda pour arriver aux attibuts
                .mat-input-server:focus {
                    .mat-form-field-label {
                        top: #{$form-field-label-top};
                        width: 100%;
                        transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                        -webkit-transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                        -ms-transform: translateY(0) scale(1);
                    }

                    /* Change Autocomplete styles in Chrome*/
                    //.mat-form-field-autofill-control=input
                    .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper,
                    .mat-form-field-autofill-control:-webkit-autofill:hover + .mat-form-field-label-wrapper,
                    .mat-form-field-autofill-control:-webkit-autofill:focus + .mat-form-field-label-wrapper {
                        .mat-form-field-label {
                            top: 0;
                            width: 100%;
                            transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                            -webkit-transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                            -ms-transform: translateY(0) scale(1);
                        }
                    }
                }
            }
        }

        &.mat-focused .mat-form-field-label {
            color: $form-field-input-label-color-focused;
        }

        /* * **************** fin label **************** */

        &.field-full-height {
            height: 100%;
            margin: 0px;
            min-height: 100%;

            .mat-form-field-wrapper {
                height: 100%;

                .mat-form-field-flex {
                    height: calc(100% - 1.8em);

                    .mat-form-field-infix {
                        height: 100%;

                        textarea {
                            height: 100%;
                        }
                    }
                }
            }
        }


    }

    .mat-select-panel {
        .mat-option {
            .mat-pseudo-checkbox {
                background-color: $mat-form-field-checkbox-background-color;
                border-radius: $mat-form-field-checkbox-border-radius;
                color: $mat-form-field-checkbox-color; // cadre
                &.mat-pseudo-checkbox-checked, &.mat-pseudo-checkbox-indeterminate {
                    background-color: $mat-form-field-checkbox-background-color-checked;
                    color: $mat-form-field-checkbox-color-checked; // cadre
                }

            }

            .mat-option-text {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: black;
                    position: absolute;
                    bottom: 9px;
                }
            }

            &.mat-selected:not(.mat-option-disabled) {
                color: $mat-form-field-option-color;
            }
        }

    }

}


/*
*       mat-select-panel
 */

div.mat-select-panel.mat-primary .mat-select-content,
div.mat-select-panel.mat-primary .mat-select-panel-done-animating {
    background-color: $form-field-select-panel-background-color;
    color: white;
}

.mat-form-field {

    &.mat-form-field-appearance-legacy .mat-form-field-label {
        top: 1.28125em;
    }

    .mat-select-arrow {
        width: 24px;
        height: 24px;
        margin: 0 4px;
        border: none;
        background-color: $mat-form-field__select-arrow--color;
        -webkit-mask-image: icon('chevron-up.svg');
        mask-image: icon('chevron-up.svg');

    }

    .mat-select.mat-focused .mat-select-arrow {
        background-color: mat.get-color-from-palette($accent);
    }

    .mat-select.mat-select-invalid .mat-select-arrow {
        background-color: mat.get-color-from-palette($warn);
    }
}

.mat-option:not(.mat-option-disabled) {

    &.mat-active {
        outline: 2px solid black;
        outline-offset: -2px;
    }

    &.mat-selected {
        color: $default-font-color !important;
    }
}

mat-checkbox.mat-checkbox.mat-accent {
    label.mat-checkbox-layout {
        .mat-checkbox-inner-container { // déco
            // <input
            span.mat-ripple.mat-checkbox-ripple {
            }

            .mat-checkbox-frame {
            }

            .mat-checkbox-background {
                border: #{$mat-form-field-checkbox-border};
                border-radius: $mat-form-field-checkbox-border-radius;
                background-color: $mat-form-field-checkbox-background-color;

                svg.mat-checkbox-checkmark path {
                    stroke: $mat-form-field-checkbox-checkmark-stroke-color !important;
                }

                .mat-checkbox-mixedmark {
                }

                //
            }
        }

        .mat-checkbox-label { // text

        }
    }

    &.mat-checkbox-indeterminate {
        background-color: $mat-form-field-checkbox-background-color;
    }

    &.mat-checkbox-checked {

    }
}






