@use '@angular/material' as mat;
// @import "../abstracts";
.count-entities {
    color: rgba($default-font-color, 0.8);
    font: $form-field-input-font;
}

%filters_bgb_bdg_z2 {
    background-color: white;
    border: 1px solid var(--gris-de-contour);
    border-radius: 5px;
    @include mat.elevation(2);
}

.filters {
    padding: 18px 0 8px 0;
    background-color: $filter--background-color;
    color: $filter--color;
    border-radius: $filter--border-radius;

    @if ($filter-extend) {
        @extend #{$filter-extend};
    }

    @extend %mat-form-field-label-on-top;

    .mat-form-field.mat-form-field-should-float,
    .mat-form-field.mat-form-field-can-float,
    .mat-form-field.mat-form-field-appearance-legacy .mat-form-field-wrapper {
        min-width: 260px;
        margin-right: 10px;
        &.field-school {
            min-width: 190px;
        }
    }

    button.mat-raised-button {
        .count {
            margin-right: -13px;
            padding: 0 5px;
        }
    }

    .mat-toolbar-row, .mat-toolbar-single-row {

    }

    .btn-collapse {
        color: $default-font-color;
        border-color: $filter__button-collapse--border-color !important;

        @extend %button--basic;

        span{
        }
        .mat-icon {
            color: $filter__button-collapse--mat-icon--color;
            border-color: $filter__button-collapse--mat-icon--border-color;
        }
        &:not(.mat-stroked-button) {
            color: $default-font-color;
        }
    }

    // TODO remove :not selector :(
    button, button:not(.mat-stroked-button) {
        @extend %button--accent;

        &[disabled] {
            color: $filter__button--disabled--color;
        }
    }
    // ---------- datepicker
     button:not(.mat-stroked-button) svg.mat-datepicker-toggle-default-icon > path:first-child { // bad default heritage
        fill: currentColor;
    }
    // ---------- rechercher
    .filters-field-launchSearch {
        margin-right: 20px;
    }

    .groups-list__filters__button {
        @include button("primary", $bordered-gradient: false);
    }


}


