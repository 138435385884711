$fake-card--background-color: null !default;
$fake-card--color: null !default;
$card__header--breadcrumb--background-color: null !default;
$card__header--breadcrumb--color: null !default;

app-mindmap-list {
    & > div.page-layout.simple {
        flex-basis: inherit; // remove auto
    }

    .mindmap-card-list {
        > .content {
            @include mx-2;
            @include my-2;
            @include grid($sm-col: 2, $lg-col: 2, $xl-col: 3, $gap: 22);
        }

        .card-split-media-info .mat-card.mat-card {
            padding-bottom: 60px; // 60px is the height of the footer
        }

        .fake-card {
            @include fake-card;
        }

        .fake-card .mat-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }
}

app-mindmap-card {

    .mat-card {

        .mat-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}