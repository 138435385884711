html {
    font-size: $root-em; // Root element font-size
}

body {
    @include body-font;
    color: var(--text, #006AA6);
}

html,
body {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: var(--text, #006AA6);
    background-color: var(--appbackground, #FFF);
}

h1,
.h1 {
    @include h1-font;
}

h2,
.h2 {
    @include h2-font;
}

h3,
.h3 {
    @include h3-font;
}

h4,
.h4 {
    @include h4-font;
}

b,
.bold {
    @include bold-font;
}

caption,
.caption {
    @include caption-font;
}

button,
.button {
    @include button-font;
    cursor: pointer;
}

a{
    cursor: pointer;
}

input,
.input {
    @include input-font;
}

.page-layout {
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;

    //box-shadow: inset 0 0 0px 10px #d000ff;
    //border: 5px solid #de5eff;
}

.content, content {
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;

    //box-shadow: inset 0 0 0px 10px #00ff24;
}

// ****  @modules  ****
// --> voir base/base
router-outlet + *:not(router-outlet) { // Cible les modules que l'on charge
    // Layout
    display: flex;
    flex-direction: column; // organisation verticale
    flex-grow: 1; // occupe l'espace vertical disponible (PAS DE height 100% !)
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;

    overflow: auto;
}