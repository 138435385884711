// --------------------------------------------------
// ANIMATIONS
// --------------------------------------------------

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-order-matching {

    mat-card.activities-card {

        &.order-sentence {

            .mat-card-content {
                flex-direction: column;
                display: flex;
                justify-content: center;

                #container-for-answers-selected > div,
                .available-answers > button {
                    min-height: 65px;
                    @include py-0;
                    @include px-2;
                    gap: inherit;
                    transform: translateY(0);
                    flex: 1;
                    height: auto;
                    min-width: 140px;
                    flex-grow: 0;

                    span,
                    label {
                        text-align: center;
                    }
                }

                #container-for-answers-selected > div {
                    &.pristine {
                        box-shadow: none;
                        border: none;
                        border-bottom: 3px solid rgba(var(--textdisabled-rgb), 0.8);
                        border-radius: 0;
                        span {
                            display: none;
                        }
                        &:before, &:after {
                            display: none;
                        }
                    }
                }

                .available-answers > button {
                    &.was-correct {
                        box-shadow: none;
                        border: none;
                        background-color: rgba(var(--textdisabled-rgb), 0.3);
                        span {
                            display: none;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }

                #container-for-answers-selected,
                .available-answers {
                    flex-direction: row;
                    display: flex;
                    flex-wrap: wrap;
                    flex: 1;
                    align-items: center;
                    gap: 10px;
                    max-width: inherit;
                    align-content: center;
                    justify-content: center;
                }

                .available-answers {
                    order: 2;
                    align-content: flex-start;
                }

                #container-for-answers-selected { // non mutualisable nom spécifique
                    order: 1;
                }
            }
        }

        &:not(.order-sentence) {
            @include matching-typology-layout;

            #container-for-answers-selected {

                .button-order {

                    label {
                        text-align: center;
                        animation: fade-in 500ms ease-in-out;
                    }

                    &.pristine {

                        span {
                            font-size: #{set-fluid-scale(40px, 80px)};
                        }
                    }
                }
            }
        }
    }
}