@use '@angular/material' as mat;
@use "sass:map";

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------
.editor-panel__lesson-meta-form__dialog {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    app-lesson-meta-form-as-dialog {
        background-color: var(--faux_blanc, #F9FBFF);
        flex: 1;
        display: flex;
        flex-direction: column;
        @include g-3;
        .mat-dialog-title {
            margin: 0;
            h2 {
                margin-right: auto;
            }
        }
        .mat-dialog-content {
            flex: 1;
            display: flex;
            @include pt-0;
            @include px-3;
            @include pb-2;
        }
    }
}


// --------------------------------------------------
// STYLE
// --------------------------------------------------
.editor-panel__lesson-meta-form__dialog {

    .mat-dialog-title {
        margin-right: auto;
    }

    // primary, secondary, tertiary, dark
    .editor-panel__lesson-meta-form__dialog__button-back {
        @include button(secondary, $icon-on-Right: true, $button-bordered: true);
    }
    .editor-panel__lesson-meta-form__dialog__button-valid-and-quit {
        @include button(primary, $icon-on-Right: true);
    }

    .mat-form-field-label {
        width: 100% !important;

        transform: inherit !important;
        top: 0 !important;
        // transform: translateY(-1.34375em) scale(0.75);

        width: 100%;
        // background-color: aqua;
        mat-label {
            gap: 20px;
            justify-content: space-between;
        }
    }
    .wrapper-form-field {
        position: relative;
    }
    .mat-form-field-label mat-label, .mat-label {
        text-transform: uppercase;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: inline-flex;
    }
    .mat-tooltip-trigger {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--bleu_tralalere_accessible, var(--bleu_tralalere_accessible, #037CC2));
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: initial;
        z-index: 100;


        .mat-icon {
            min-width: 15px;
            min-height: 15px;
            line-height: 15px;
            width: 15px;
            height: 15px;
            flex-basis: 15px;
            svg {
                width: 100%;
                height: 100%;
                min-height: 100%;
                min-width: 100%;
            }
        }
    }


    .wrapper-form-field.lesson-meta-form__description {
        .mat-form-field-infix {
            padding-bottom: 0;
            textarea.mat-input-element {
                border: 1px solid $primary-c;
                margin-bottom: 0;
                border-radius: 0;
            }
        }

    }
    wrapper-form-field lesson-meta-form__usages,
    .wrapper-form-field.lesson-meta-form__description {
        mat-label {
            flex: 1;
            display: flex;
            gap: 20px;
            justify-content: space-between;
        }
    }

    .mat-tab-list {
        @include tabs(tertiary, $counter: true);
    }
    .mat-chip {
        @include chips(primary);
    }
    .mat-form-field-type-mat-chip-list {
        background-color: var(--blanc, #FFF);
        border: 1px solid var(--gris-de-contour, #CBD1DB);
        border-radius: var(--radius-s, 6px);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .mat-form-field-wrapper {
            padding: 0;
            .mat-form-field-flex {
                padding: 10px 20px 20px 20px;
                .mat-form-field-infix {
                    border: 0;
                    padding-top: 0;
                    .mat-chip-list-wrapper {
                        padding: 0;
                        margin: 0;
                        .mat-form-field-flex {
                            .mat-form-field-infix {

                            }
                        }
                    }
                }
            }
            .mat-form-field-underline {
                margin: 0 20px;
                width: calc(100% - 40px);
            }

        }

    }


}

