

// --------------------------------------------------
// RADIUS (ALL SIDES)
// --------------------------------------------------

@mixin r-0 {
    border-radius: $radius-0;
}

@mixin r-1 {
    border-radius: $radius-1;
}

@mixin r-2 {
    border-radius: $radius-2;
}

@mixin r-3 {
    border-radius: $radius-3;
}

@mixin r-4 {
    border-radius: $radius-4;
}

@mixin r-5 {
    border-radius: $radius-5;
}

@mixin r-rounded {
    border-radius: 50%;
}

// --------------------------------------------------
// TOP SIDE RADIUS
// --------------------------------------------------

@mixin rt-0 {
    border-top-left-radius: $radius-0;
    border-top-right-radius: $radius-0;
}

@mixin rt-1 {
    border-top-left-radius: $radius-1;
    border-top-right-radius: $radius-1;
}

@mixin rt-2 {
    border-top-left-radius: $radius-2;
    border-top-right-radius: $radius-2;
}

@mixin rt-3 {
    border-top-left-radius: $radius-3;
    border-top-right-radius: $radius-3;
}

@mixin rt-4 {
    border-top-left-radius: $radius-4;
    border-top-right-radius: $radius-4;
}

@mixin rt-5 {
    border-top-left-radius: $radius-5;
    border-top-right-radius: $radius-5;
}

// --------------------------------------------------
// BOTTOM SIDE RADIUS
// --------------------------------------------------

@mixin rb-0 {
    border-bottom-left-radius: $radius-0;
    border-bottom-right-radius: $radius-0;
}

@mixin rb-1 {
    border-bottom-left-radius: $radius-1;
    border-bottom-right-radius: $radius-1;
}

@mixin rb-2 {
    border-bottom-left-radius: $radius-2;
    border-bottom-right-radius: $radius-2;
}

@mixin rb-3 {
    border-bottom-left-radius: $radius-3;
    border-bottom-right-radius: $radius-3;
}

@mixin rb-4 {
    border-bottom-left-radius: $radius-4;
    border-bottom-right-radius: $radius-4;
}

@mixin rb-5 {
    border-bottom-left-radius: $radius-5;
    border-bottom-right-radius: $radius-5;
}

// --------------------------------------------------
// LEFT SIDE RADIUS
// --------------------------------------------------

@mixin rl-0 {
    border-top-left-radius: $radius-0;
    border-bottom-left-radius: $radius-0;
}

@mixin rl-1 {
    border-top-left-radius: $radius-1;
    border-bottom-left-radius: $radius-1;
}

@mixin rl-2 {
    border-top-left-radius: $radius-2;
    border-bottom-left-radius: $radius-2;
}

@mixin rl-3 {
    border-top-left-radius: $radius-3;
    border-bottom-left-radius: $radius-3;
}

@mixin rl-4 {
    border-top-left-radius: $radius-4;
    border-bottom-left-radius: $radius-4;
}

@mixin rl-5 {
    border-top-left-radius: $radius-5;
    border-bottom-left-radius: $radius-5;
}

// --------------------------------------------------
// RIGHT SIDE RADIUS
// --------------------------------------------------

@mixin rr-0 {
    border-top-right-radius: $radius-0;
    border-bottom-right-radius: $radius-0;
}

@mixin rr-1 {
    border-top-right-radius: $radius-1;
    border-bottom-right-radius: $radius-1;
}

@mixin rr-2 {
    border-top-right-radius: $radius-2;
    border-bottom-right-radius: $radius-2;
}

@mixin rr-3 {
    border-top-right-radius: $radius-3;
    border-bottom-right-radius: $radius-3;
}

@mixin rr-4 {
    border-top-right-radius: $radius-4;
    border-bottom-right-radius: $radius-4;
}

@mixin rr-5 {
    border-top-right-radius: $radius-5;
    border-bottom-right-radius: $radius-5;
}