
/*
*   **************  FIGMA VARIABLES *****************
 */
$padding-lateral-viewport: 16px; // espace latéraux de l'écran
$gap-vertical-viewport: 10px; // espace entre les éléments verticaux
:root {
    --padding-lateral-viewport: #{$padding-lateral-viewport};
    --gap-vertical-viewport: #{$gap-vertical-viewport};
}

/*
*   **************  VARIABLES PRECEDENTES *****************
 */

:root {
    --activities-width: 80%;
    --max-small-device: 70%;
}

$validated-color: #008A9C;
$warning-color: #ED6723;
$success-color: #679A00;
$error-color: darkred;


$fuse-app-lessons-tab-overflow: auto !default;

$mat-toolbar-row-height: 64px;
$fuse-app-lessons-header: 56px;
$app-generic-stepper-height: 70px;
$app-generic-stepper-width: 70px;

$keyboard-grid-item-tab-height: 6vh;
$keyboard-grid-item-height: 8vh;
$latex-keyboard-height: $keyboard-grid-item-tab-height + ( $keyboard-grid-item-height * 5);

// Calculate toolbarless header height
$top-toolbar-row-and-lessons-header-height: $mat-toolbar-row-height + $fuse-app-lessons-header;

