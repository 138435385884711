
%compass-typography {

    .compass__back__link {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 25px;
        color: #2A2A2A;
        /* Desktop/button */
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 114.286% */
    }

    h2 {
        color: #343B4A;
        font-size: 2rem;
        font-style: normal;
        font-weight: 900;
        line-height: 21px; /* 105% */
    }

    h3 {
        color: #333A49;
        font-size: 2rem;
        font-style: normal;
        font-weight: 900;
        line-height: 22px; /* 122.222% */
    }

    h4 {
        color: #333A49;
        font-weight: 900;
    }

    p {
        color: #333A49;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 13.2px */
    }
}

@include classesGenerator('.', $compassNavButtonColorSet);

$compass__explorer__nav--min-height: 25vh !default;
$compass__explorer__nav__button__media_4_display: null !default;
$compass__explorer__nav__button__media_5_display: null !default;

$compass__explorer__nav__button__media_1_position: bottom center !default;
$compass__explorer__nav__button__media_4_position: bottom right !default;
$compass__explorer__nav__button__media_5_position: bottom left !default;

$compass__explorer__nav__button__media_4_icon_justify: flex-start !default;
$compass__explorer__nav__button__media_4_text_justify: flex-start !default;

app-compass {

    .compass {
        @extend %compass-typography;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 2.4rem 4.2rem 4.2rem;
        background-color: $compass--background-color;

        h2 {
            margin: 0 0 2rem;

            a {
                text-decoration: none;
            }
        }

        .compass__helper {
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            gap: 1.8rem;
            background-color: $primary-c;
            color: $white-c;
            border-radius: 18px;

            .compass__helper-content {
                max-width: 60%;
                padding: 1.8rem;

                .compass__helper-title {
                    color: inherit;
                }

                .compass__helper-text {

                    p {
                        color: inherit;
                    }
                }
            }

            .compass__helper-image-wrapper {
                flex: 1;
                background: transparent image('backgrounds/compass__helper__header-image.png') no-repeat right top;
                background-size: contain;
            }
        }

        .compass__explorer__nav {
            @include gridTemplate($tpl-compass);
            min-height: $compass__explorer__nav--min-height; //25vh;

            &__title {
                font-size: 1.5rem;
                font-weight: bold;
            }

            &__button {
                @include button(
                    $type: compass,
                    $bordered-gradient: false,
                );
                font-size: 2rem;
                line-height: 2rem;
                align-items: center;
                color: $white-c;
                margin: 0;
                padding: 0;
                display: flex;
                gap: 0;
                background-repeat: no-repeat;
                background-position: bottom right;
                background-size: auto 100%;

                mat-icon.mat-icon {
                    order: 2;
                    flex: 1;
                    margin: 0;
                    width: unset;
                    display: flex;
                    justify-content: flex-start;

                    svg {
                        width: unset;
                    }
                }

                span {
                    order: 1;
                    flex: 2;
                    display: flex;
                    align-items: center;
                    padding: 8px 14px !important;
                }

                .compass__explorer__nav__button__media {
                    flex: 2;
                    order: 3;
                    align-self: stretch;
                    max-width: 90px;
                    position: relative;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    flex-direction: column;
                    background-position: -20px 130px;
                    background-size: contain;
                    padding: 0 14px;

                    span {
                        flex: 1;
                    }

                    .mat-icon {
                        flex: 1;
                        align-self: flex-end;
                        align-items: flex-start;
                        max-width: 24px;

                        svg {
                            max-width: 24px;
                            max-height: 24px;
                        }
                    }
                }

                &:nth-child(1) {
                    background-size: auto 50%;
                    background-position: $compass__explorer__nav__button__media_1_position;

                    .mat-icon {
                        align-items: flex-start;
                    }
                }

                &:nth-child(2) {
                    flex-direction: column-reverse;
                    color: $default-font-color;
                    background-position: top left;
                    background-size: 50% 50%;
                    align-items: flex-end;

                    .mat-icon {
                        align-items: flex-end; 
                        color: currentColor !important;
                    }
                }

                &:nth-child(4) {
                    background-image: $compass__explorer__nav__button__media_4_display;
                    background-position: $compass__explorer__nav__button__media_4_position;

                    mat-icon.mat-icon {
                        justify-content: $compass__explorer__nav__button__media_4_icon_justify; // center;
                    }
                    span {
                        justify-content: $compass__explorer__nav__button__media_4_text_justify; //flex-end;
                    }

                }
                &:nth-child(5) {
                    background-image: $compass__explorer__nav__button__media_5_display;

                    background-position: $compass__explorer__nav__button__media_5_position;

                }
            }
        }

        .compass__news__content.grid-compass {
            padding: unset;
            background-color: unset;
        }
    }
}

.grid-compass {
    @include grid(
        $sm-col: 1,
        $lg-col: 2,
        $xl-col: 3,
        $gap: 18,
    );
    padding: 20px;
    border-radius: 18px;
    background: rgba(50, 58, 73, 0.05);
}

.mat-progress-spinner {
    margin: auto;
}

.compass-themes__content {

    &.grid-compass {
        border-radius: 0;
    }
}