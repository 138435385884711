@import "variables-intentions";

/* Design Team */
/* color */
// TODO @import "color/variables-components-color";
/* number */
$backdrop-blur: $numbers-1;
$icon-size-m: $numbers-3;
$title-size: 1.125rem;
$icon-size-l: $numbers-4;
$profile-picture-size: $icon-size-l;
$button-disabled-shadow-blur: $numbers-0;
$button-disabled-shadow-spread: $numbers-0;
$button-disabled-shadow-x-offset: $numbers-0;
$button-disabled-shadow-y-offset: $numbers-0;
$button-outlined-danger-default-shadow-blur: $numbers-0;
$button-outlined-danger-default-shadow-spread: $numbers-0;
$button-outlined-danger-default-shadow-x-offset: $numbers-0;
$button-outlined-danger-default-shadow-y-offset: $numbers-0;
$button-outlined-danger-disabled-shadow-blur: $numbers-0;
$button-outlined-danger-disabled-shadow-spread: $numbers-0;
$button-outlined-danger-disabled-shadow-x-offset: $numbers-0;
$button-outlined-danger-disabled-shadow-y-offset: $numbers-0;
$button-outlined-danger-hover-shadow-blur: $numbers-0;
$button-outlined-danger-hover-shadow-spread: $numbers-0;
$button-outlined-danger-hover-shadow-x-offset: $numbers-0;
$button-outlined-danger-hover-shadow-y-offset: $numbers-0;
$button-outlined-danger-pressed-shadow-blur: $numbers-0;
$button-outlined-danger-pressed-shadow-spread: $numbers-0;
$button-outlined-danger-pressed-shadow-x-offset: $numbers-0;
$button-outlined-danger-pressed-shadow-y-offset: $numbers-0;
$button-outlined-primary-default-shadow-blur: $numbers-0;
$button-outlined-primary-default-shadow-spread: $numbers-0;
$button-outlined-primary-default-shadow-x-offset: $numbers-0;
$button-outlined-primary-default-shadow-y-offset: $numbers-0;
$button-outlined-primary-disabled-shadow-blur: $numbers-0;
$button-outlined-primary-disabled-shadow-spread: $numbers-0;
$button-outlined-primary-disabled-shadow-x-offset: $numbers-0;
$button-outlined-primary-disabled-shadow-y-offset: $numbers-0;
$button-outlined-primary-hover-shadow-blur: $numbers-0;
$button-outlined-primary-hover-shadow-spread: $numbers-0;
$button-outlined-primary-hover-shadow-x-offset: $numbers-0;
$button-outlined-primary-hover-shadow-y-offset: $numbers-0;
$button-outlined-primary-pressed-shadow-blur: $numbers-0;
$button-outlined-primary-pressed-shadow-spread: $numbers-0;
$button-outlined-primary-pressed-shadow-x-offset: $numbers-0;
$button-outlined-primary-pressed-shadow-y-offset: $numbers-0;
$button-outlined-secondary-default-shadow-blur: $numbers-0;
$button-outlined-secondary-default-shadow-spread: $numbers-0;
$button-outlined-secondary-default-shadow-x-offset: $numbers-0;
$button-outlined-secondary-default-shadow-y-offset: $numbers-0;
$button-outlined-secondary-disabled-shadow-blur: $numbers-0;
$button-outlined-secondary-disabled-shadow-spread: $numbers-0;
$button-outlined-secondary-disabled-shadow-x-offset: $numbers-0;
$button-outlined-secondary-disabled-shadow-y-offset: $numbers-0;
$button-outlined-secondary-hover-shadow-blur: $numbers-0;
$button-outlined-secondary-hover-shadow-spread: $numbers-0;
$button-outlined-secondary-hover-shadow-x-offset: $numbers-0;
$button-outlined-secondary-hover-shadow-y-offset: $numbers-0;
$button-outlined-secondary-pressed-shadow-blur: $numbers-0;
$button-outlined-secondary-pressed-shadow-spread: $numbers-0;
$button-outlined-secondary-pressed-shadow-x-offset: $numbers-0;
$button-outlined-secondary-pressed-shadow-y-offset: $numbers-0;
$button-standard-danger-default-shadow-blur: $numbers-0;
$button-standard-danger-default-shadow-spread: $numbers-0;
$button-standard-danger-default-shadow-x-offset: $numbers-0;
$button-standard-danger-default-shadow-y-offset: $numbers-0;
$button-standard-danger-hover-shadow-blur: $numbers-0;
$button-standard-danger-hover-shadow-spread: $numbers-0;
$button-standard-danger-hover-shadow-x-offset: $numbers-0;
$button-standard-danger-hover-shadow-y-offset: $numbers-0;
$button-standard-danger-pressed-shadow-blur: $numbers-0;
$button-standard-danger-pressed-shadow-spread: $numbers-0;
$button-standard-danger-pressed-shadow-x-offset: $numbers-0;
$button-standard-danger-pressed-shadow-y-offset: $numbers-0;
$button-standard-primary-default-shadow-blur: $numbers-0;
$button-standard-primary-default-shadow-spread: $numbers-0;
$button-standard-primary-default-shadow-x-offset: $numbers-0;
$button-standard-primary-default-shadow-y-offset: $numbers-0;
$button-standard-primary-hover-shadow-blur: $numbers-0;
$button-standard-primary-hover-shadow-spread: $numbers-0;
$button-standard-primary-hover-shadow-x-offset: $numbers-0;
$button-standard-primary-hover-shadow-y-offset: $numbers-0;
$button-standard-primary-pressed-shadow-blur: $numbers-0;
$button-standard-primary-pressed-shadow-spread: $numbers-0;
$button-standard-primary-pressed-shadow-x-offset: $numbers-0;
$button-standard-primary-pressed-shadow-y-offset: $numbers-0;
$button-standard-secondary-default-shadow-blur: $numbers-0;
$button-standard-secondary-default-shadow-spread: $numbers-0;
$button-standard-secondary-default-shadow-x-offset: $numbers-0;
$button-standard-secondary-default-shadow-y-offset: $numbers-0;
$button-standard-secondary-hover-shadow-blur: $numbers-0;
$button-standard-secondary-hover-shadow-spread: $numbers-0;
$button-standard-secondary-hover-shadow-x-offset: $numbers-0;
$button-standard-secondary-hover-shadow-y-offset: $numbers-0;
$button-standard-secondary-pressed-shadow-blur: $numbers-0;
$button-standard-secondary-pressed-shadow-spread: $numbers-0;
$button-standard-secondary-pressed-shadow-x-offset: $numbers-0;
$button-standard-secondary-pressed-shadow-y-offset: $numbers-0;
$button-text-danger-default-shadow-blur: $numbers-0;
$button-text-danger-default-shadow-spread: $numbers-0;
$button-text-danger-default-shadow-x-offset: $numbers-0;
$button-text-danger-default-shadow-y-offset: $numbers-0;
$button-text-danger-disabled-shadow-blur: $numbers-0;
$button-text-danger-disabled-shadow-spread: $numbers-0;
$button-text-danger-disabled-shadow-x-offset: $numbers-0;
$button-text-danger-disabled-shadow-y-offset: $numbers-0;
$button-text-danger-hover-shadow-blur: $numbers-0;
$button-text-danger-hover-shadow-spread: $numbers-0;
$button-text-danger-hover-shadow-x-offset: $numbers-0;
$button-text-danger-hover-shadow-y-offset: $numbers-0;
$button-text-danger-pressed-shadow-blur: $numbers-0;
$button-text-danger-pressed-shadow-spread: $numbers-0;
$button-text-danger-pressed-shadow-x-offset: $numbers-0;
$button-text-danger-pressed-shadow-y-offset: $numbers-0;
$button-text-primary-default-shadow-blur: $numbers-0;
$button-text-primary-default-shadow-spread: $numbers-0;
$button-text-primary-default-shadow-x-offset: $numbers-0;
$button-text-primary-default-shadow-y-offset: $numbers-0;
$button-text-primary-disabled-shadow-blur: $numbers-0;
$button-text-primary-disabled-shadow-spread: $numbers-0;
$button-text-primary-disabled-shadow-x-offset: $numbers-0;
$button-text-primary-disabled-shadow-y-offset: $numbers-0;
$button-text-primary-hover-shadow-blur: $numbers-0;
$button-text-primary-hover-shadow-spread: $numbers-0;
$button-text-primary-hover-shadow-x-offset: $numbers-0;
$button-text-primary-hover-shadow-y-offset: $numbers-0;
$button-text-primary-pressed-shadow-blur: $numbers-0;
$button-text-primary-pressed-shadow-spread: $numbers-0;
$button-text-primary-pressed-shadow-x-offset: $numbers-0;
$button-text-primary-pressed-shadow-y-offset: $numbers-0;
$button-text-secondary-default-shadow-blur: $numbers-0;
$button-text-secondary-default-shadow-spread: $numbers-0;
$button-text-secondary-default-shadow-x-offset: $numbers-0;
$button-text-secondary-default-shadow-y-offset: $numbers-0;
$button-text-secondary-disabled-shadow-blur: $numbers-0;
$button-text-secondary-disabled-shadow-spread: $numbers-0;
$button-text-secondary-disabled-shadow-x-offset: $numbers-0;
$button-text-secondary-disabled-shadow-y-offset: $numbers-0;
$button-text-secondary-hover-shadow-blur: $numbers-0;
$button-text-secondary-hover-shadow-spread: $numbers-0;
$button-text-secondary-hover-shadow-x-offset: $numbers-0;
$button-text-secondary-hover-shadow-y-offset: $numbers-0;
$button-text-secondary-pressed-shadow-blur: $numbers-0;
$button-text-secondary-pressed-shadow-spread: $numbers-0;
$button-text-secondary-pressed-shadow-x-offset: $numbers-0;
$button-text-secondary-pressed-shadow-y-offset: $numbers-0;
$card-header-max-height: $numbers-7;
$card-header-image-icon-size: $numbers-7;
$card-header-image-size: $numbers-9;
$gap-reset: $numbers-0;
$gap-2xs: $numbers-half-1;
$gap-xs: $numbers-1;
$gap-m-half: $numbers-half-3;
$gap-s: $numbers-2;
$gap-xl-half: $numbers-half-6;
$gap-m: $numbers-3;
$gap-l: $numbers-4;
$gap-xl: $numbers-5;
$gap-2xl: $numbers-6;
$gap-3xl: $numbers-7;
$header-height: $numbers-11;
$menu-menu-height: $numbers-macbook-air-h;
$menu-button-item-default-shadow-blur: $numbers-0;
$menu-button-item-default-shadow-spread: $numbers-0;
$menu-button-item-default-shadow-x-offset: $numbers-0;
$menu-button-item-default-shadow-y-offset: $numbers-0;
$menu-button-item-hover-shadow-blur: $numbers-0;
$menu-button-item-hover-shadow-spread: $numbers-0;
$menu-button-item-hover-shadow-x-offset: $numbers-0;
$menu-button-item-hover-shadow-y-offset: $numbers-0;
$menu-button-item-pressed-shadow-blur: $numbers-0;
$menu-button-item-pressed-shadow-spread: $numbers-0;
$menu-button-item-pressed-shadow-x-offset: $numbers-0;
$menu-button-item-pressed-shadow-y-offset: $numbers-0;
$menu-button-item-selected-shadow-blur: $numbers-0;
$menu-button-item-selected-shadow-spread: $numbers-0;
$menu-button-item-selected-shadow-x-offset: $numbers-0;
$menu-button-item-selected-shadow-y-offset: $numbers-0;
$menu-button-menu-default-shadow-blur: $numbers-0;
$menu-button-menu-default-shadow-spread: $numbers-0;
$menu-button-menu-default-shadow-x-offset: $numbers-0;
$menu-button-menu-default-shadow-y-offset: $numbers-0;
$menu-button-menu-hover-shadow-blur: $numbers-0;
$menu-button-menu-hover-shadow-spread: $numbers-0;
$menu-button-menu-hover-shadow-x-offset: $numbers-0;
$menu-button-menu-hover-shadow-y-offset: $numbers-0;
$menu-button-menu-pressed-shadow-blur: $numbers-0;
$menu-button-menu-pressed-shadow-spread: $numbers-0;
$menu-button-menu-pressed-shadow-x-offset: $numbers-0;
$menu-button-menu-pressed-shadow-y-offset: $numbers-0;
$menu-button-mini-item-default-shadow-blur: $numbers-0;
$menu-button-mini-item-default-shadow-spread: $numbers-0;
$menu-button-mini-item-default-shadow-x-offset: $numbers-0;
$menu-button-mini-item-default-shadow-y-offset: $numbers-0;
$menu-button-mini-item-hover-shadow-blur: $numbers-0;
$menu-button-mini-item-hover-shadow-spread: $numbers-0;
$menu-button-mini-item-hover-shadow-x-offset: $numbers-0;
$menu-button-mini-item-hover-shadow-y-offset: $numbers-0;
$menu-button-mini-item-pressed-shadow-blur: $numbers-0;
$menu-button-mini-item-pressed-shadow-spread: $numbers-0;
$menu-button-mini-item-pressed-shadow-x-offset: $numbers-0;
$menu-button-mini-item-pressed-shadow-y-offset: $numbers-0;
$menu-button-mini-item-selected-shadow-blur: $numbers-0;
$menu-button-mini-item-selected-shadow-spread: $numbers-0;
$menu-button-mini-item-selected-shadow-x-offset: $numbers-0;
$menu-button-mini-item-selected-shadow-y-offset: $numbers-0;
$modal-header-max-height: $numbers-7;
$modal-header-image-icon-size: $numbers-7;
$modal-header-image-size: $numbers-9;
$radius-reset: $numbers-0;
$radius-xs: $numbers-1;
$radius-s: $numbers-2; // 6
$radius-m: $numbers-3; // 8
$radius-l: $numbers-4; // 10
$radius-xl: $numbers-5; // 12
$radius-rounded: $numbers-rounded;
$screen-min-height: 43.125rem;
$stroke-reset: $numbers-0;
$stroke-default: $numbers-unknown;
$table-columns: $numbers-40;
$tag-default-light-stroke-size: $stroke-reset;
$tag-selection-selected-stroke-size: $stroke-default;